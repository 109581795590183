<template>
<div class="kt-container kt-grid__item kt-grid__item--fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3
                            v-if="isEdit"
                            class="kt-portlet__head-title"
                        >
                            Edit Category
                        </h3>
                        <h3
                            v-else
                            class="kt-portlet__head-title"
                        >
                            Create Anecdotal Category
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <router-link
                            :to="{name: 'AnecdotalSetup'}"
                            class="btn btn-icon"
                        >
                            <i class="la la-close" />
                        </router-link>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <div class="row w-100">
                        <div class="col-lg-8">
                            <div class="kt-section">
                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label><span class="kt-font-danger">*</span> Category Title</label>
                                        <input
                                            v-model="anecdotalEventCategoryTitle"
                                            type="text"
                                            class="form-control"
                                            maxlength="255"
                                            placeholder=""
                                        >
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label><span class="kt-font-danger">*</span> Point Value</label>
                                        <input
                                            v-model="pointValue"
                                            type="number"
                                            class="form-control"
                                        >
                                        <span class="form-text text-muted mt-2">Big integers and negative values are supported</span>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class=" w-100"><span class="kt-font-danger">*</span> Color</label>
                                        <div class="input-group">
                                            <ColorSwatch
                                                class="input-group-prepend"
                                                :color-change="selectCategoryColor"
                                                :color-selected="categoryColor"
                                                :item-index="0"
                                            />

                                            <input
                                                type="text"
                                                class="form-control"
                                                disabled="disabled"
                                                :value="categoryColor"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class=" w-100">Icon</label>

                                        <div class="input-group kt-input-icon">
                                            <EmojiPicker
                                                :initial-emoji="categoryIcon"
                                                :on-selected="emojiPicked"
                                                class="input-group-prepend"
                                            />

                                            <input
                                                type="text"
                                                class="form-control pr-5"
                                                readonly="readonly"
                                                :value="categoryIconShortName"
                                            >
                                            <span
                                                v-if="categoryIconShortName"
                                                class="kt-input-icon__icon kt-input-icon__icon--right"
                                                @click.stop.prevent="clearIcon"
                                            >
                                                <span><i class="la la-close" /></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Default Description</label>
                                    <textarea
                                        v-model="defaultDescription"
                                        rows="3"
                                        class="form-control"
                                    />
                                    <span
                                        class="form-text text-muted"
                                        style="font-size: 0.8rem;"
                                    >
                                        The default description can be used as instructions,
                                        or as a template to the anecdotal author.
                                    </span>
                                </div>
                                <div class="form-group">
                                    <label class="pb-2">Share With Guardians:</label>
                                    <div class="kt-radio-inline">
                                        <label class="kt-radio">
                                            <input
                                                v-model="defaultGuardianShare"
                                                type="radio"
                                                :value="null"
                                                :checked="!defaultGuardianShare"
                                            >Author Decides<span />
                                        </label>
                                        <label class="kt-radio kt-radio--success">
                                            <input
                                                v-model="defaultGuardianShare"
                                                type="radio"
                                                :value="'Always'"
                                                :checked="defaultGuardianShare == 'Always'"
                                            >Always Shared<span />
                                        </label>
                                        <label class="kt-radio kt-radio--danger">
                                            <input
                                                v-model="defaultGuardianShare"
                                                type="radio"
                                                :value="'Never'"
                                                :checked="defaultGuardianShare == 'Never'"
                                            >Never Shared<span />
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="pb-2">Share With Students:</label>
                                    <div class="kt-radio-inline">
                                        <label class="kt-radio">
                                            <input
                                                v-model="defaultStudentShare"
                                                type="radio"
                                                :value="null"
                                                :checked="!defaultStudentShare"
                                            >Author Decides<span />
                                        </label>
                                        <label class="kt-radio kt-radio--success">
                                            <input
                                                v-model="defaultStudentShare"
                                                type="radio"
                                                :value="'Always'"
                                                :checked="defaultStudentShare == 'Always'"
                                            >Always Shared<span />
                                        </label>
                                        <label class="kt-radio kt-radio--danger">
                                            <input
                                                v-model="defaultStudentShare"
                                                type="radio"
                                                :value="'Never'"
                                                :checked="defaultStudentShare == 'Never'"
                                            >Never Shared<span />
                                        </label>
                                    </div>
                                    <span
                                        class="form-text text-muted mt-3"
                                        style="font-size: 0.9rem;"
                                    >
                                        Sharing settings are not retroactive, it will only affect new anecdotals created in this category.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-lg-4 pl-4"
                            style="border-left: 1px dashed #ebedf2"
                        >
                            <div class="kt-section">
                                <div class="form-group row">
                                    <div class="col-12">
                                        <label class=" w-100">
                                            Preview:
                                        </label>

                                        <div
                                            v-if="category && category.anecdotalEventCategoryTitle"
                                            class="media mt-1 mb-1"
                                        >
                                            <div
                                                class="media-body"
                                                style="max-width: 52px;"
                                            >
                                                <CircleBadge
                                                    :color="category.categoryColor"
                                                    :icon="category.categoryIcon"
                                                    :title="category.anecdotalEventCategoryTitle"
                                                />
                                            </div>
                                            <div class="align-self-center ml-3 kt-media-group mr-3">
                                                <div style="height: 72px;">
                                                    <AnecdotalEventCategoryLabel
                                                        class="mt-0 big-category"
                                                        :anecdotal-event-category="category"
                                                        :large="true"
                                                    />
                                                    <div v-if="category.pointValue > 0">
                                                        <p class="kt-font-success point-container">
                                                            <span>+{{ abbrevNumber(category.pointValue) }}</span>
                                                        </p>
                                                    </div>
                                                    <div v-if="category.pointValue < 0">
                                                        <p class="kt-font-danger point-container">
                                                            <span> {{ abbrevNumber(category.pointValue) }}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p v-else>
                                            Preview not available
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-section">
                                <div class="w-100">
                                    <label class=" w-100">
                                        Subscribers:
                                        <span
                                            v-if="subscribers.length > 0"
                                            class="text-muted pull-right"
                                        >
                                            {{ subscribers.length }} subscribed
                                        </span>
                                    </label>
                                    <AnecdotalTeacherPicker :on-selected="userAdded" />
                                    <div class="tab-content student-list p-3">
                                        <div class="kt-widget4">
                                            <div
                                                v-for="(user, idx) in subscribed"
                                                :key="`${idx}_anec_subscribers`"
                                                class="kt-widget4__item"
                                            >
                                                <div class="kt-widget4__pic kt-widget4__pic--pic">
                                                    <UserAvatar :avatar-user="user">
                                                        <div class="kt-badge kt-badge--lg kt-badge--success">
                                                            {{ user.lastName.substring(0, 1) }}{{ user.firstName.substring(0, 1) }}
                                                        </div>
                                                    </UserAvatar>
                                                </div>

                                                <div class="kt-widget4__info">
                                                    <a
                                                        href="#"
                                                        class="kt-widget4__username"
                                                        @click.stop.prevent="removeUserAtIndex(idx)"
                                                    >
                                                        {{ user.lastName }}, {{ user.firstName }}
                                                    </a>
                                                    <p>
                                                        {{ user.schoolEmail }}
                                                    </p>
                                                </div>
                                                <a
                                                    href="#"
                                                    @click.stop.prevent="removeUserAtIndex(idx)"
                                                >
                                                    <i class="la la-close" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        class="form-text text-muted"
                                        style="font-size: 0.85rem"
                                    >
                                        Subscribers are automatically added as participants
                                        and emailed when new anecdotal events
                                        of this category are created or edited.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions">
                        <div class="row">
                            <div class="col-lg-6" />
                            <div class="col-lg-6 kt-align-right">
                                <button
                                    type="button"
                                    class="btn btn-primary pull-right"
                                    data-dismiss="modal"
                                    :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                                    @click.stop.prevent="save"
                                >
                                    Save Category
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import 'emoji-mart-vue-fast/css/emoji-mart.css';

import { mapState } from 'vuex';
import Types from '../../store/Types';
import * as network from '../../network';
import ColorSwatch from '../../components/ColorSwatch.vue';
import EmojiPicker from '../../components/EmojiPicker.vue';
import AnecdotalEventCategoryLabel from '../../components/AnecdotalEventCategoryLabel.vue';
import AnecdotalTeacherPicker from './AnecdotalTeacherPicker.vue';
import UserAvatar from '../../components/UserAvatar.vue';
import CircleBadge from '../../components/CircleBadge.vue';

export default {
    name: 'EditAnecdotalCategory',
    components: {
        ColorSwatch,
        EmojiPicker,
        CircleBadge,
        AnecdotalEventCategoryLabel,
        AnecdotalTeacherPicker,
        UserAvatar,
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus();
            },
        },
    },
    data() {
        return {
            saving: false,
            debounceSave: null,
            isEdit: false,
            anecdotalEventCategoryId: null,
            anecdotalEventCategoryTitle: null,
            categoryColor: 'Blue',
            categoryIcon: null,
            categoryIconShortName: null,
            pointValue: 0,
            defaultGuardianShare: null,
            defaultStudentShare: null,
            defaultDescription: null,
            search: '',
            subscribers: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            teachers: (state) => state.database.teachers,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
        }),
        subscribed() {
            const v = this;
            return this.subscribers.map((s) => v.teachers.find((t) => t.userId == s.userId) || null)
                .filter((x) => x);
        },
        category() {
            const v = this;
            if (v.isEdit) {
                return {
                    schoolId: v.user.school.schoolId,
                    anecdotalEventCategoryId: this.anecdotalEventCategoryId,
                    anecdotalEventCategoryTitle: (this.anecdotalEventCategoryTitle || '').trim(),
                    categoryIcon: this.categoryIcon,
                    categoryIconShortName: this.categoryIconShortName,
                    categoryColor: this.categoryColor,
                    pointValue: this.pointValue,
                    defaultGuardianShare: this.defaultGuardianShare,
                    defaultStudentShare: this.defaultStudentShare,
                    defaultDescription: this.defaultDescription,
                    subscribers: this.subscribers,
                };
            }
            return {
                schoolId: v.user.school.schoolId,
                anecdotalEventCategoryTitle: (this.anecdotalEventCategoryTitle || '').trim(),
                categoryIcon: this.categoryIcon,
                categoryIconShortName: this.categoryIconShortName,
                categoryColor: this.categoryColor,
                pointValue: this.pointValue,
                defaultGuardianShare: this.defaultGuardianShare,
                defaultStudentShare: this.defaultStudentShare,
                defaultDescription: this.defaultDescription,
                subscribers: this.subscribers,
            };
        },
    },
    watch: {
        anecdotalEventCategories() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        emojiPicked(emoji) {
            if (!emoji || !emoji.colons) {
                this.categoryIconShortName = null;
                this.categoryIcon = null;
                return;
            }


            this.categoryIconShortName = emoji.colons;
            this.categoryIcon = emoji.native;
        },
        clearIcon() {
            this.categoryIcon = null;
            this.categoryIconShortName = null;
        },
        removeUserAtIndex(index) {
            const user = this.subscribers[index];
            this.subscribers = this.subscribers.filter((t) => t.userId != user.userId);
        },
        userAdded(user) {
            // remove if exists
            this.subscribers = this.subscribers.filter((s) => user.userId !== s.userId);
            // adds it back
            this.subscribers.push({ userId: user.userId });
        },
        selectCategoryColor(color) {
            this.categoryColor = color;
        },
        init() {
            const { anecdotalEventCategoryId } = this.$route.params;
            if (!anecdotalEventCategoryId) {
                this.open();
                return;
            }
            const category = this.anecdotalEventCategories.find((c) => c.anecdotalEventCategoryId == anecdotalEventCategoryId);
            if (!category) {
                this.open();
                return;
            }
            this.open(category);
        },
        open(category) {
            this.isEdit = Boolean(category);
            if (this.isEdit) {
                this.anecdotalEventCategoryId = category.anecdotalEventCategoryId;
                this.anecdotalEventCategoryTitle = category.anecdotalEventCategoryTitle;
                this.categoryColor = category.categoryColor;
                this.categoryIcon = category.categoryIcon;
                this.categoryIconShortName = category.categoryIconShortName;
                this.pointValue = category.pointValue;
                this.defaultGuardianShare = category.defaultGuardianShare;
                this.defaultStudentShare = category.defaultStudentShare;
                this.defaultDescription = category.defaultDescription;
                this.subscribers = category.subscribers.map((s) => ({ userId: s.userId }));
            } else {
                this.anecdotalEventCategoryId = null;
                this.anecdotalEventCategoryTitle = 'New';
                this.categoryColor = 'Blue';
                this.categoryIcon = null;
                this.categoryIconShortName = null;
                this.pointValue = 10;
                this.defaultGuardianShare = null;
                this.defaultStudentShare = null;
                this.defaultDescription = null;
                this.subscribers = [];
            }
        },
        save() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            if (v.isEdit) {
                const { schoolId, schoolTermId } = v.user.school;
                const { anecdotalEventCategoryId } = v.category;
                const anecdotalEventCategory = v.category;
                network.anecdotals.editCategory({ url: { schoolId, schoolTermId, anecdotalEventCategoryId }, body: { anecdotalEventCategory } }, (err) => {
                    this.saving = false;
                    if (err) return v.showError(err);
                    v.$store.commit(Types.mutations.EDIT_ANECDOTAL_EVENT_CATEGORY, { anecdotalEventCategory });
                    this.$router.push({ name: 'AnecdotalSetup' });
                });
            } else {
                const { category, anecdotalEventCategories } = v;
                const { schoolId, schoolTermId } = v.user.school;
                category.schoolId = schoolId;
                if (!category.anecdotalEventCategoryTitle) return v.showError('Please enter a Category Name');

                const categoryFound = anecdotalEventCategories.find((c) => c.anecdotalEventCategoryTitle.toLowerCase() == category.anecdotalEventCategoryTitle.toLowerCase());
                if (categoryFound) return v.showError('Category Name already exists');

                network.anecdotals.addCategory({ url: { schoolId, schoolTermId }, body: { anecdotalEventCategory: category } }, (err, res) => {
                    this.saving = false;
                    if (err) return v.showError(err);
                    v.$store.commit(Types.mutations.ADD_ANECDOTAL_EVENT_CATEGORY, { anecdotalEventCategory: res.anecdotalEventCategory });
                    this.$router.push({ name: 'AnecdotalSetup' });
                });
            }
        },
    },
};
</script>

<style src="../../css/colorSwatch.css" />

<style scoped>
.emoji-mart {
    border: none !important;
    max-height: 300px;
}

.media-body {
    width: 200px;
}

.big-category {
    display: block;
    width: 132px;
    overflow: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.point-container {
    width: 80px;
    font-weight: 600;
    font-size: 1.8rem;
}
</style>
