var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "kt-badge kt-badge--inline kt-badge--unified",
      class: _vm.className,
    },
    [_vm._v(_vm._s(_vm.title))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }