var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-container kt-grid__item kt-grid__item--fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _vm.isEdit
                  ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
                      _vm._v(" Edit Category "),
                    ])
                  : _c("h3", { staticClass: "kt-portlet__head-title" }, [
                      _vm._v(" Create Anecdotal Category "),
                    ]),
              ]),
              _c(
                "div",
                { staticClass: "kt-portlet__head-toolbar" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-icon",
                      attrs: { to: { name: "AnecdotalSetup" } },
                    },
                    [_c("i", { staticClass: "la la-close" })]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "row w-100" }, [
                _c("div", { staticClass: "col-lg-8" }, [
                  _c("div", { staticClass: "kt-section" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-6" }, [
                        _vm._m(0),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.anecdotalEventCategoryTitle,
                              expression: "anecdotalEventCategoryTitle",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            maxlength: "255",
                            placeholder: "",
                          },
                          domProps: { value: _vm.anecdotalEventCategoryTitle },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.anecdotalEventCategoryTitle =
                                $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-6" }, [
                        _vm._m(1),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pointValue,
                              expression: "pointValue",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number" },
                          domProps: { value: _vm.pointValue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.pointValue = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "form-text text-muted mt-2" },
                          [
                            _vm._v(
                              "Big integers and negative values are supported"
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-6" }, [
                        _vm._m(2),
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c("ColorSwatch", {
                              staticClass: "input-group-prepend",
                              attrs: {
                                "color-change": _vm.selectCategoryColor,
                                "color-selected": _vm.categoryColor,
                                "item-index": 0,
                              },
                            }),
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { type: "text", disabled: "disabled" },
                              domProps: { value: _vm.categoryColor },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-lg-6 col-md-6 col-sm-6" }, [
                        _c("label", { staticClass: "w-100" }, [_vm._v("Icon")]),
                        _c(
                          "div",
                          { staticClass: "input-group kt-input-icon" },
                          [
                            _c("EmojiPicker", {
                              staticClass: "input-group-prepend",
                              attrs: {
                                "initial-emoji": _vm.categoryIcon,
                                "on-selected": _vm.emojiPicked,
                              },
                            }),
                            _c("input", {
                              staticClass: "form-control pr-5",
                              attrs: { type: "text", readonly: "readonly" },
                              domProps: { value: _vm.categoryIconShortName },
                            }),
                            _vm.categoryIconShortName
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "kt-input-icon__icon kt-input-icon__icon--right",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.clearIcon.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._m(3)]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Default Description")]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.defaultDescription,
                            expression: "defaultDescription",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "3" },
                        domProps: { value: _vm.defaultDescription },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.defaultDescription = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "form-text text-muted",
                          staticStyle: { "font-size": "0.8rem" },
                        },
                        [
                          _vm._v(
                            " The default description can be used as instructions, or as a template to the anecdotal author. "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "pb-2" }, [
                        _vm._v("Share With Guardians:"),
                      ]),
                      _c("div", { staticClass: "kt-radio-inline" }, [
                        _c("label", { staticClass: "kt-radio" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.defaultGuardianShare,
                                expression: "defaultGuardianShare",
                              },
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: null,
                              checked: !_vm.defaultGuardianShare,
                              checked: _vm._q(_vm.defaultGuardianShare, null),
                            },
                            on: {
                              change: function ($event) {
                                _vm.defaultGuardianShare = null
                              },
                            },
                          }),
                          _vm._v("Author Decides"),
                          _c("span"),
                        ]),
                        _c(
                          "label",
                          { staticClass: "kt-radio kt-radio--success" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.defaultGuardianShare,
                                  expression: "defaultGuardianShare",
                                },
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: "Always",
                                checked: _vm.defaultGuardianShare == "Always",
                                checked: _vm._q(
                                  _vm.defaultGuardianShare,
                                  "Always"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.defaultGuardianShare = "Always"
                                },
                              },
                            }),
                            _vm._v("Always Shared"),
                            _c("span"),
                          ]
                        ),
                        _c(
                          "label",
                          { staticClass: "kt-radio kt-radio--danger" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.defaultGuardianShare,
                                  expression: "defaultGuardianShare",
                                },
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: "Never",
                                checked: _vm.defaultGuardianShare == "Never",
                                checked: _vm._q(
                                  _vm.defaultGuardianShare,
                                  "Never"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.defaultGuardianShare = "Never"
                                },
                              },
                            }),
                            _vm._v("Never Shared"),
                            _c("span"),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "pb-2" }, [
                        _vm._v("Share With Students:"),
                      ]),
                      _c("div", { staticClass: "kt-radio-inline" }, [
                        _c("label", { staticClass: "kt-radio" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.defaultStudentShare,
                                expression: "defaultStudentShare",
                              },
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: null,
                              checked: !_vm.defaultStudentShare,
                              checked: _vm._q(_vm.defaultStudentShare, null),
                            },
                            on: {
                              change: function ($event) {
                                _vm.defaultStudentShare = null
                              },
                            },
                          }),
                          _vm._v("Author Decides"),
                          _c("span"),
                        ]),
                        _c(
                          "label",
                          { staticClass: "kt-radio kt-radio--success" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.defaultStudentShare,
                                  expression: "defaultStudentShare",
                                },
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: "Always",
                                checked: _vm.defaultStudentShare == "Always",
                                checked: _vm._q(
                                  _vm.defaultStudentShare,
                                  "Always"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.defaultStudentShare = "Always"
                                },
                              },
                            }),
                            _vm._v("Always Shared"),
                            _c("span"),
                          ]
                        ),
                        _c(
                          "label",
                          { staticClass: "kt-radio kt-radio--danger" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.defaultStudentShare,
                                  expression: "defaultStudentShare",
                                },
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: "Never",
                                checked: _vm.defaultStudentShare == "Never",
                                checked: _vm._q(
                                  _vm.defaultStudentShare,
                                  "Never"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.defaultStudentShare = "Never"
                                },
                              },
                            }),
                            _vm._v("Never Shared"),
                            _c("span"),
                          ]
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "form-text text-muted mt-3",
                          staticStyle: { "font-size": "0.9rem" },
                        },
                        [
                          _vm._v(
                            " Sharing settings are not retroactive, it will only affect new anecdotals created in this category. "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-lg-4 pl-4",
                    staticStyle: { "border-left": "1px dashed #ebedf2" },
                  },
                  [
                    _c("div", { staticClass: "kt-section" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", { staticClass: "w-100" }, [
                            _vm._v(" Preview: "),
                          ]),
                          _vm.category &&
                          _vm.category.anecdotalEventCategoryTitle
                            ? _c("div", { staticClass: "media mt-1 mb-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "media-body",
                                    staticStyle: { "max-width": "52px" },
                                  },
                                  [
                                    _c("CircleBadge", {
                                      attrs: {
                                        color: _vm.category.categoryColor,
                                        icon: _vm.category.categoryIcon,
                                        title:
                                          _vm.category
                                            .anecdotalEventCategoryTitle,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "align-self-center ml-3 kt-media-group mr-3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { height: "72px" } },
                                      [
                                        _c("AnecdotalEventCategoryLabel", {
                                          staticClass: "mt-0 big-category",
                                          attrs: {
                                            "anecdotal-event-category":
                                              _vm.category,
                                            large: true,
                                          },
                                        }),
                                        _vm.category.pointValue > 0
                                          ? _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "kt-font-success point-container",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "+" +
                                                        _vm._s(
                                                          _vm.abbrevNumber(
                                                            _vm.category
                                                              .pointValue
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.category.pointValue < 0
                                          ? _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "kt-font-danger point-container",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.abbrevNumber(
                                                            _vm.category
                                                              .pointValue
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _c("p", [_vm._v(" Preview not available ")]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-section" }, [
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c("label", { staticClass: "w-100" }, [
                            _vm._v(" Subscribers: "),
                            _vm.subscribers.length > 0
                              ? _c(
                                  "span",
                                  { staticClass: "text-muted pull-right" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.subscribers.length) +
                                        " subscribed "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("AnecdotalTeacherPicker", {
                            attrs: { "on-selected": _vm.userAdded },
                          }),
                          _c(
                            "div",
                            { staticClass: "tab-content student-list p-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-widget4" },
                                _vm._l(_vm.subscribed, function (user, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `${idx}_anec_subscribers`,
                                      staticClass: "kt-widget4__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget4__pic kt-widget4__pic--pic",
                                        },
                                        [
                                          _c(
                                            "UserAvatar",
                                            { attrs: { "avatar-user": user } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-badge kt-badge--lg kt-badge--success",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        user.lastName.substring(
                                                          0,
                                                          1
                                                        )
                                                      ) +
                                                      _vm._s(
                                                        user.firstName.substring(
                                                          0,
                                                          1
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget4__info" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "kt-widget4__username",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeUserAtIndex(
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(user.lastName) +
                                                  ", " +
                                                  _vm._s(user.firstName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(user.schoolEmail) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.removeUserAtIndex(idx)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "la la-close",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "form-text text-muted",
                              staticStyle: { "font-size": "0.85rem" },
                            },
                            [
                              _vm._v(
                                " Subscribers are automatically added as participants and emailed when new anecdotal events of this category are created or edited. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__foot" }, [
              _c("div", { staticClass: "kt-form__actions" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-6" }),
                  _c("div", { staticClass: "col-lg-6 kt-align-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-right",
                        class: {
                          "kt-spinner kt-spinner--sm kt-spinner--light":
                            _vm.saving,
                        },
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" Save Category ")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Category Title"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Point Value"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "w-100" }, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Color"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "la la-close" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }