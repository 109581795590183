<template>
<span

    class="kt-badge kt-badge--inline kt-badge--unified"
    :class="className"
>{{ title }}</span>
</template>

<script>

// @ts-ignore
import cssVars from '../css/colors.scss';

export default {
    name: 'AnecdotalEventCategoryLabel',
    props: {
        anecdotalEventCategory: {
            type: Object,
            required: true,
        },
        large: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        color() {
            const { anecdotalEventCategory } = this;
            if (anecdotalEventCategory?.deleted) return 'Red';
            return this.anecdotalEventCategory?.categoryColor || 'Grey';
        },
        hexColor() {
            // const color = this.anecdotalEventCategory?.categoryColor || 'Grey';
            return cssVars[this.color];
        },
        className() {
            return `${this.color} ${this.large ? 'kt-badge--bold kt-badge--lg' : ''}`;
        },
        title() {
            return this.anecdotalEventCategory?.anecdotalEventCategoryTitle || '';
        },
    },

};
</script>
<style scoped src='../css/colors.scss' lang="scss" />
<style scoped>
span.kt-badge--lg {
    padding: 0.4rem 1.2rem !important;
    text-transform: uppercase;
}
</style>
